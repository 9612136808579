import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { BsPencil } from "react-icons/bs";
import Address from "./Address";
import "./Profile.css";
import { RiProfileFill, RiCustomerService2Line } from "react-icons/ri";
import { CiDeliveryTruck, CiLogout } from "react-icons/ci";
import { TbTruckDelivery } from "react-icons/tb";
import { PiPasswordFill } from "react-icons/pi";
debugger;
function ProfileSideNav() {
    const [userData, setUserData] = useState(null);
    const [Email, setEmail] = useState(null);
    const [Name, setName] = useState(null);
    const [profileImage, setProfileImage] = useState(null);

    useEffect(() => {
        // const authorId = localStorage.getItem("author_id");
        // setEmail(email);
        // setName(email);
        //         // Fetch user profile details, including image
        //         if (authorId) {
            //             fetchUserProfile(authorId);
            //         }
            fetchUserProfile()
        }, []);
    
        const fetchUserProfile = async () => {
        const authorId = localStorage.getItem("author_id");
        try {
            const response = await fetch(`https://itilmaamapi.adef.tech/Get_User?authorid=${authorId}&usertype=4`);
            if (!response.ok) {
                throw new Error("Failed to fetch user profile");
            }
            const data = await response.json();
            
            // Assuming the API returns an object with image URL (adjust if the key is different)
            if (data && data.Image) {
                setProfileImage(data.Image); // Set the profile image URL from the API
                setName(data.Name); // Set the profile image URL from the API
                setEmail(data.Email); // Set the profile image URL from the API
            }
            
        } catch (error) {
            console.error("Error fetching user profile:", error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("author_id");
        window.location.href = "/";
    };

    return (
        <>
            <Container>
                <Row className="mt-5 mb-5">
                    <Col md={12}>
                        <div className="profilesidenav">
                            <div className="">
                                <div className="d-flex profileimage">
                                    <img
                                        className="profile-icons"
                                        variant="top"
                                        src={profileImage}
                                    />
                                    <div className="user-info ml-3">
                                        <h5>{Name}</h5>
                                        <p>{Email}</p>
                                    </div>
                                </div>
                            </div>
                            <Card>
                                <Card.Body>
                                    <div className="menu-buttons">
                                        <Link to="/profile"
                                            className="btnsidenav mb-3 mt-3"
                                            variant=" text-start"
                                        >
                                            <RiProfileFill /> My Profile
                                        </Link>
                                        <Link to="/address"
                                            className="btnsidenav mb-3"
                                            variant=" text-start"
                                        >
                                            <CiDeliveryTruck /> Delivery Address
                                        </Link>
                                        <Link to="/myorders"
                                            className="btnsidenav mb-3"
                                            variant=" text-start"
                                        >
                                            <TbTruckDelivery /> Orders
                                        </Link>
                                        <Link to="/customercare"
                                            className="btnsidenav mb-3"
                                            variant=" text-start"
                                        >
                                            <RiCustomerService2Line /> Customer Care
                                        </Link>
                                        <Link to="/changepassword"
                                            className="btnsidenav mb-3"
                                            variant=" text-start"
                                        >
                                            <PiPasswordFill /> Change Password
                                        </Link>
                                        <Link
                                            className="btnsidenav mb-3"
                                            variant="light text-start"
                                            onClick={handleLogout}
                                        >
                                            <CiLogout /> Logout
                                        </Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ProfileSideNav;
