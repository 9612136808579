// import React, { useState, useEffect } from "react";
// import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
// import "./Profile.css";
// import ProfileSideNav from "./ProfileSideNav";
// import { BsPencil } from "react-icons/bs";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css"
// function Profile() {
//   const [userData, setUserData] = useState(null);
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [authorId, setAuthorId] = useState(localStorage.getItem('author_id') || "");
//   const [password,setPassword] = useState(userData.Password || '')
//   const [name,setName] = useState(userData.Name || '')
//   const [email,setEmail] = useState(userData.Email || '')
//   const [phone,setPhone] = useState(userData.Phone || '')

//   useEffect(() => {
//     fetchUserProfile();
//   }, [authorId]);

//   const handleEditModeToggle = () => {
//     setIsEditMode((prevMode) => !prevMode);
//   };
//   const fetchUserProfile = async () => {
//     if (!authorId) return;

//     try {
//       const response = await fetch(
//         `https://itilmaamapi.adef.tech/Get_User?authorid=${authorId}&usertype=4`
//       );
//       if (!response.ok) {
//         throw new Error('Failed to fetch user profile');
//       }
//       const data = await response.json();
//       setUserData(data);
//     } catch (error) {
//       console.error("Error fetching user profile:", error);
//     }
//   };
//   const handleInputChange = (field, value) => {
//     setUserData((prevUserData) => ({
//       ...prevUserData,
//       [field]: value,
//     }));
//   };
//   debugger;
//   console.log('=====> USERDATA',userData)
//   const handleSave = async (e) => {
//     e.preventDefault();
//     debugger;
//     const formData = new URLSearchParams(userData);

//     console.log("Form Data:", formData.toString());

//     const url = `https://itilmaamapi.adef.tech/Edit_User`;

//     debugger;
//     try {
//       const response = await fetch(url, {
//         method: "POST",
//         body: formData,
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//       });

//       const responseData = await response.json();

//       if (responseData.responseCode == 1) {

//         toast.success(responseData.responsemessage, {
//           position: "top-right",
//           autoClose: 3000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//         });
//         setIsEditMode(false);
//         fetchUserProfile(); 
//         // Redirect to home page
//         window.location.href = "/home";
//       } else {
//         // Login failed
//         toast.error(
//           responseData.responsemessage || "Invalid credentials.",
//           {
//             position: "top-right",
//             autoClose: 3000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//           }
//         );
//       }
//     } catch (error) {
//       // Error during login
//       toast.error("An error occurred during login.", {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//       console.error("Fetch error:", error);
//     }
//   };
//   return (
//     <>

//       <Container>
//         <Row className="mt-5 mb-5">
//           <Col md={4}>
//             <ProfileSideNav />
//           </Col>
//           <Col md={8} className="mt-5">
//             {userData && (
//               <Form className="rightsidedata">
//                 <Row>
//                   <Col md={6}>
//                     <Form.Group controlId="formName">
//                       <Form.Label>Name</Form.Label>
//                       <Form.Control
//                         type="text"
//                         value={userData.Name || ""}
//                         className="custom-form-control"
//                         readOnly={!isEditMode}
//                         onChange={(e) => handleInputChange("Name", e.target.value)}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={6}>
//                     <Form.Group controlId="formEmail">
//                       <Form.Label>Email</Form.Label>
//                       <Form.Control
//                         type="email"
//                         value={userData.Email || ""}
//                         className="custom-form-control"
//                         readOnly
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={6}>
//                     <Form.Group controlId="formNumber">
//                       <Form.Label>Number</Form.Label>
//                       <Form.Control
//                         type="number"
//                         value={userData.Phone || ""}
//                         className="custom-form-control"
//                         readOnly={!isEditMode}
//                         onChange={(e) => handleInputChange("Phone", e.target.value)}
//                       />
//                     </Form.Group>
//                   </Col>
//                   <Col md={6}>
//                     <Form.Group controlId="formPassword">
//                       <Form.Label>Password</Form.Label>
//                       <Form.Control
//                         type="password"
//                         value={userData.Password || ""}
//                         className="custom-form-control"
//                         readOnly={!isEditMode}
//                         onChange={(e) => handleInputChange("Password", e.target.value)}
//                       />
//                     </Form.Group>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col md={12} className="d-flex justify-content-between">
//                     <Button variant="primary" onClick={handleSave} disabled={!isEditMode}>
//                       Save
//                     </Button>
//                     <BsPencil size={24} className="edit-icon" onClick={handleEditModeToggle} />
//                   </Col>
//                 </Row>
//               </Form>
//             )}
//           </Col>
//         </Row>
//         <ToastContainer />
//       </Container>
//     </>
//   );
// }

// export default Profile;
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import ProfileSideNav from './ProfileSideNav';
import { BsPencil } from 'react-icons/bs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Profile() {
  const [userData, setUserData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [image, setImage] = useState(null);
  const authorId = localStorage.getItem('author_id');

  useEffect(() => {
    fetchUserProfile();
  }, [authorId]);

  const fetchUserProfile = async () => {
    if (!authorId) return;

    try {
      const response = await fetch(
        `https://itilmaamapi.adef.tech/Get_User?authorid=${authorId}&usertype=4`
      );
      const data = await response.json();
      setUserData(data);
      console.log("Fetched user data:", data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleInputChange = (field, value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [field]: value,
    }));
  };

  const handleEditModeToggle = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSave = async (e) => {
  e.preventDefault();

  if (!userData || !authorId) return;

  // Ensure phone number and password are not empty
  if (!userData.Phone || !userData.Password) {
    toast.error('Phone number and Password are required.', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    return; // Stop further execution if validation fails
  }

  // Prepare the data model to be sent as application/x-www-form-urlencoded
  const dataToSend = new URLSearchParams({
    Name: userData.Name || '',
    Email: userData.Email || '',
    Phone: userData.Phone || '',
    Password: userData.Password || '',
    id: authorId,
    Image: image || '', // Include the image in the payload (base64), if needed
    usertype: '4',
  }).toString(); // Convert to URL-encoded string

  try {
    const response = await fetch('https://itilmaamapi.adef.tech/Edit_User', {
      method: 'POST',
      body: dataToSend, // Send the URL-encoded string
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", // Correct content type for form data
      },
    });

    const responseData = await response.json();


    if (responseData.responseCode === 0) {
      toast.success(responseData.responsemessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsEditMode(false);
      fetchUserProfile();
      window.location.href = '/home'; // Navigate to home on success
    } else {
      toast.error(responseData.responsemessage || 'Error saving profile', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  } catch (error) {
    toast.error('An error occurred during the update.', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    console.error('Fetch error:', error);
  }
};

  // Convert selected image file to Base64
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result); // Save the Base64 string
      };

      reader.readAsDataURL(file); // Read file as Base64
    }
  };

  return (
    <>
      <Container>
        <Row className="mt-5 mb-5">
          <Col md={4}>
            <ProfileSideNav />
          </Col>
          <Col md={8} className="mt-5">
            {userData && (
              <Form className="rightsidedata">
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={userData.Name || ''}
                        className="custom-form-control"
                        readOnly={!isEditMode}
                        onChange={(e) => handleInputChange('Name', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={userData.Email || ''}
                        className="custom-form-control"
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formNumber">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        value={userData.Phone || ''}
                        className="custom-form-control"
                        readOnly={!isEditMode}
                        onChange={(e) => handleInputChange('Phone', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={userData.Password || ''}
                        className="custom-form-control"
                        readOnly={!isEditMode}
                        onChange={(e) => handleInputChange('Password', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group controlId="formImage">
                      <Form.Label>Upload Image</Form.Label>
                      <Form.Control
                        type="file"
                        className="custom-form-control"
                        disabled={!isEditMode}
                        onChange={handleImageChange}
                      />
                      {image && (
                        <img
                          src={image} // Base64 string for preview
                          alt="Preview"
                          style={{ width: '100px', height: '100px', marginTop: '10px', borderRadius:'9px' }}
                        />
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-between">
                    <Button variant="primary" onClick={handleSave} disabled={!isEditMode} style={{marginTop:'19px'}}>
                      Save
                    </Button>
                    <BsPencil size={24} className="edit-icon" onClick={handleEditModeToggle} />
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}






export default Profile;

