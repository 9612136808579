import React from "react";
import BrowserItems from "./BrowseItems";
import Products from "./Prodcuts"
import FeaturedProducts from "./FeaturedProducts";
import Faqs from "./Faqs";
import Listing from "./Listing";
import Testimonials from "./Testimonials";
import Loader from './Loader'; 
debugger;
const Home = ({ isLoading }) => {
  console.log('------->',isLoading);

    return(
        <div>
            {isLoading ? ( 
                <Loader isLoading={isLoading} />
            ) : (
            
                <>
                    <BrowserItems/>
                    <Products/>
                    <FeaturedProducts/>
                    <Faqs/>
                    <Listing/>
                    <Testimonials/>
                </>
            )}
        </div>
    )
}

export default Home;
