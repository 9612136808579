// import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
// import axios from "axios";
// import "./Css/OrderDetails.css";

// function OrderDetails({ cartamount, coupon }) {
//   console.log(cartamount);
//   console.log(coupon);
//   const [finalAmount, setFinalAmount] = useState(0);
//   const discountAmount = localStorage.getItem("couponamount");
//   useEffect(() => {

//     debugger;
//     if (cartamount && coupon != null) {
//       debugger;
//       //const discountAmount = coupon.discountamount || 0;
//       const discountAmount = localStorage.getItem("couponamount");
//       if (discountAmount == undefined) {
//         const discount = discountAmount == undefined && discountAmount == null ? discountAmount : 0;
//         const totalPrice = cartamount.price || 0;

//         const calculatedAmount = totalPrice - discount;
//         setFinalAmount(calculatedAmount);
//       }
//       else{
//         const totalPrice = cartamount.price || 0;

//         const calculatedAmount = totalPrice - discountAmount;
//         setFinalAmount(calculatedAmount);
//       }


//     }
//     else if (coupon == null) {
//       const totalPrice = cartamount.price || 0;

//       const calculatedAmount = totalPrice;
//       setFinalAmount(calculatedAmount);
//     }
//   }, [cartamount, coupon]);

//   return (
//     <div className="order-details-container mt-4">
//       {cartamount && (
//         <div className="order-details-content p-3">
//           <h5 className="order-details-title">Order Details</h5>
//           <div className="order-details-item mt-4 d-flex justify-content-between">
//             <h6 className="order-details-label">Bag total  </h6>
//             <h6 className="order-details-value">$ {cartamount.amount}</h6>
//           </div>
//           <div className="order-details-item mt-3 bag-discount d-flex justify-content-between">
//             <h6 className="order-details-label">Bag discount  </h6>
//             <h6 className="order-details-value">-$ {cartamount.discount_amount}</h6>
//           </div>
//           {discountAmount ? (
//             <div className="order-details-item mt-3 bag-discount d-flex justify-content-between">
//               <h6 className="order-details-label">Coupon </h6>
//               <h6 className="order-details-value">-$ {discountAmount}</h6>
//             </div>
//           ) : null}
//           <div className="order-details-item mt-3 order-total d-flex justify-content-between">
//             <h6 className="order-details-label">Order Total</h6>
//             <h6 className="order-details-value">$ {finalAmount}</h6>
//           </div>
//           <p className='cod mt-3'>Cash on Delivery Available</p>
//         </div>
//       )}
//     </div>
//   );
// }

// export default OrderDetails;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Css/OrderDetails.css";

function OrderDetails({ cartamount, coupon }) {
  console.log(cartamount);
  console.log(coupon);

  const [finalAmount, setFinalAmount] = useState(0);
  const [deliveryprice, setDeliveryPrice] = useState(0);  // Initialize delivery price
  const [loading, setLoading] = useState(true);  // Loading state for fetching API data

  const discountAmount = localStorage.getItem("couponamount");
  const cartId = localStorage.getItem("cart_id");

  // Fetch delivery price from the API
  useEffect(() => {
    if (cartId) {
      axios
        .get(`https://itilmaamapi.adef.tech/Cartlist?cart_id=${cartId}`)
        .then((response) => {
          console.log("API Response:", response);
          // Assuming the API response contains the delivery price under the 'delivery_price' field
          const deliveryPriceFromAPI = response.data[0].deliveryprice;  // Assuming response data is an array
          setDeliveryPrice(deliveryPriceFromAPI || 0);  // Fallback to 0 if not found
          setLoading(false);  // Set loading to false after the API call is complete
        })
        .catch((error) => {
          console.error("Error fetching delivery price:", error);
          setLoading(false);  // Set loading to false in case of error
        });
    }
  }, [cartId]);

  // Only calculate the finalAmount after delivery price has been fetched
  useEffect(() => {
    if (cartamount && !loading) {
      const discountAmount = localStorage.getItem("couponamount");
      let totalPrice = cartamount.price || 0;

      // Apply coupon discount if available
      if (discountAmount) {
        totalPrice -= parseFloat(discountAmount);  // Subtract the coupon amount
      }

      // Add delivery price fetched from the API
      const calculatedAmount = totalPrice + deliveryprice;
      setFinalAmount(calculatedAmount);
    }
  }, [cartamount, coupon, deliveryprice, loading]);  // Trigger recalculation whenever the data changes

  if (loading) {
    return <div>Loading...</div>;  // Display a loading state until the API call completes
  }

  return (
    <div className="order-details-container mt-4">
      {cartamount && (
        <div className="order-details-content p-3">
          <h5 className="order-details-title">Order Details</h5>

          <div className="order-details-item mt-4 d-flex justify-content-between">
            <h6 className="order-details-label">Bag total</h6>
            <h6 className="order-details-value">$ {cartamount.amount}</h6>
          </div>

          <div className="order-details-item mt-3 bag-discount d-flex justify-content-between">
            <h6 className="order-details-label">Bag discount</h6>
            <h6 className="order-details-value">-$ {cartamount.discount_amount}</h6>
          </div>

          {discountAmount ? (
            <div className="order-details-item mt-3 bag-discount d-flex justify-content-between">
              <h6 className="order-details-label">Coupon</h6>
              <h6 className="order-details-value">-$ {discountAmount}</h6>
            </div>
          ) : null}

          {/* Displaying the delivery price */}
          <div className="order-details-item mt-3 d-flex justify-content-between">
            <h6 className="order-details-label">Delivery Price</h6>
            <h6 className="order-details-value">
              $ {deliveryprice > 0 ? deliveryprice : "0"}
            </h6>
          </div>

          <div className="order-details-item mt-3 order-total d-flex justify-content-between">
            <h6 className="order-details-label">Order Total</h6>
            <h6 className="order-details-value">$ {finalAmount}</h6>
          </div>
          <p className="cod mt-3">Cash on Delivery Available</p>
        </div>
      )}
    </div>
  );
}

export default OrderDetails;
